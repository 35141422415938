/* From https://dev.to/djchadderton/making-tinymce-work-with-rails-turbolinks-and-stimulus-2ooa */
import { Controller } from "@hotwired/stimulus"

// Import TinyMCE
import tinymce from 'tinymce/tinymce';

/* Omdat er ook een aantal basis scripts geladen moeten worden, voeg EVT de volgende regel toe:
 * Rails.application.config.assets.paths << Rails.root.join("node_modules/tinymce")
*/

// Import icons
import 'tinymce/icons/default';

// Import theme
import 'tinymce/themes/silver/theme';

// Import skin: see application.scss
// import 'tinymce/skins/ui/oxide/skin.min.css';

import 'tinymce/models/dom';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';

import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/fullscreen';

export default class extends Controller {
  // Targets zijn een beetje overbodig. We kunnen gewoon met selector uit de voeten.
  static targets = ['content_input', 'media_input']

  initialize () {

    // Basis configuratie
    let base_config= {
      skin: false,
      plugins: ['image', 'media', 'link', 'anchor', 'lists', 'code', 'charmap', 'fullscreen'],
      menubar: false,
      toolbar: 'undo redo | pastetext | blocks | bold italic superscript | image media| anchor link | numlist bullist | alignleft aligncenter alignright | charmap code | fullscreen',
      contextmenu: 'link image removeformat',

      // Image plugin    
      images_upload_url: '/content/content_assets/upload',
      image_caption: true,
      image_list: '/content/content_assets/tinymce_image_list',
      images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp,svg',    /// Voeg SVG toe
      link_list: '/content/content_pages/tinymce_link_list',

      language: 'nl',
      language_url: '/assets/langs/nl.js',
      content_css: '/assets/tinymce_content.css',

      // Video
      media_dimensions: false,
      media_poster: false,

      branding: false,
      toolbar_sticky: true,
      statusbar: true, 

      paste_as_text: true,
      relative_urls: false,

      block_formats: 'Paragraph=p;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5',

      formats: {
        // Changes the alignment buttons to add a class to each of the matching selector elements
        alignleft: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'align-left' },
        aligncenter: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'align-center' },
        alignright: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'align-right' },
        alignjustify: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'align-justify' }
      }
    }

    // Default main Editor
    this.main_content_config= Object.assign({}, base_config);   // Clone base_config

    // Image and Media Editor/ Side content Editor
    this.media_content_config= Object.assign({}, base_config);   // Clone base_config
    // this.media_content_config.toolbar= 'undo redo | image media | code';
    // this.media_content_config.contextmenu= 'image removeformat';

  }

  connect () {
    const img_upload_url= this.element.dataset.imagesUploadUrl;
    const img_list_url= this.element.dataset.imageListUrl;
    const link_list_url= this.element.dataset.linkListUrl;

    try {
      if( img_upload_url) this.main_content_config['images_upload_url']= img_upload_url;
      if( img_list_url) this.main_content_config['image_list']= img_list_url;
      if( link_list_url) this.main_content_config['link_list']= link_list_url;

      let main_config = Object.assign({ target: this.content_inputTarget }, this.main_content_config)
      tinymce.init(main_config)
    } catch (error) {
      // Input target not defined?
      // console.error(error);
    }

    try {
      if( img_upload_url) this.media_content_config['images_upload_url']= img_upload_url;
      if( img_list_url) this.media_content_config['image_list']= img_list_url;
      if( link_list_url) this.media_content_config['link_list']= link_list_url;

      let media_config = Object.assign({ target: this.media_inputTarget }, this.media_content_config)
      tinymce.init(media_config)
    } catch (error) {
      // Input target not defined?
      // console.error(error);
    }

  }

  disconnect () {
    tinymce.remove()
  }
}
