/* 
  Google Maps javascript controller 
  Javinto

  Alle benodigde code staat hier. We passen de voorkeursmethode Dynamic Library Import toe: https://developers.google.com/maps/documentation/javascript/load-maps-js-api
  Behalve die voor MarkerClusterer. Zie application.html voor "https://unpkg.com/@googlemaps/markerclusterer/dist/index.min.js"
*/

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'map' ]

  static gm_center_longitude= 5.12654;
  static gm_center_latitude= 52.08086;
  static gm_zoom= 7.6;
  static gm_cluster_markers= false; 

  static gm_api_key= 'AIzaSyA9A2htHsdfmGya1g9w6SkNHhPxYRCaiPI';   // Netlaw
  static gm_version= 'quarterly';
  static gm_region= 'NL';
  static gm_map_id= '61a7a0efd89aefc2';   // See https://console.cloud.google.com/google/maps-apis/studio/maps?project=netlaw-150516

  /* Declare (public) instance fields here */
  map;

  initialize() {
    if (typeof google === 'object' && typeof google.maps === 'object') {
      // Loaded
    } else {
      // Load GoogleMaps API
      (g=> {var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window; b=b[c]||(b[c]={}); var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script")); e.set("libraries",[...r]+""); for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]); e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
        key: this.constructor.gm_api_key,
        v: this.constructor.gm_version,
        language: document.querySelector('html[lang]').lang,
        region: this.constructor.gm_region,
        // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
        // Add other bootstrap parameters as needed, using camel case.
      });
    }
  }

  connect() {
    if(this.hasMapTarget) {
      // Zoom factor defined as data property?
      let zoomFactor= this.mapTarget.dataset.gmapZoom;
      if (zoomFactor===undefined) {zoomFactor=this.constructor.gm_zoom;} else {zoomFactor= parseFloat(zoomFactor)}

      // Center point defined as data property?
      let centerLatitude= this.mapTarget.dataset.gmapCenterLatitude;
      let centerLongitude= this.mapTarget.dataset.gmapCenterLongitude;
      if (centerLatitude===undefined) {
        centerLatitude= this.constructor.gm_center_latitude;
        centerLongitude= this.constructor.gm_center_longitude;
      } else {
        centerLatitude= parseFloat(centerLatitude);
        centerLongitude= parseFloat(centerLongitude);
      }

      let clusterMarkers= this.mapTarget.dataset.gmapCluster;
      if (clusterMarkers==undefined) {clusterMarkers= this.constructor.gm_cluster_markers;} else {clusterMarkers== (clusterMarkers=='true');}

      this.initMap({zoom: zoomFactor, lat: centerLatitude, lng: centerLongitude, cluster: clusterMarkers=='true'});
          
    } else {
      console.log("MapController: no map target found.")
    }
  }

  async add_markers(records, options={}) {
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    const { InfoWindow } = await google.maps.importLibrary("maps");

    // console.log(records);
    let markers= [];
    let infoWindow= new InfoWindow();

    records.forEach((record) => {
      let markerConfig= {
        map: this.map,
        position: {lat: parseFloat(record.latitude), lng: parseFloat(record.longitude)},
        title: record.label
      }

      // Optional settings
      if (record.icon) {
        // Create a different glyph icon
        let iconImg = document.createElement('img');
        iconImg.src= record.icon
        
        markerConfig.content= iconImg;
      }

      let marker= new AdvancedMarkerElement(markerConfig);

      marker.addListener('click', function() {
        infoWindow.close();
        infoWindow.setContent(record.marker_info);
        infoWindow.open(this.map, marker);
      });
      markers.push(marker);
    })

    if (options['cluster']) {
      // Cluster markers if there are too many. Note that we use 'named parameters'!  So do not change the name of the parameters
      const map= this.map;

      // Default Clusters
      const markerCluster = new markerClusterer.MarkerClusterer({ map, markers});

      // Customized by Algorithm
      // const algorithm= new markerClusterer.GridAlgorithm({maxZoom: 11});
      // const markerCluster = new markerClusterer.MarkerClusterer({ map, markers, algorithm });
    }
  }

  async initMap(options={}) {
    const { Map } = await google.maps.importLibrary("maps");

    this.map = new Map(this.mapTarget, {
      center: { lat: options['lat'], lng: options['lng'] },
      zoom: options['zoom'],
      mapId: this.constructor.gm_map_id
    });

    fetch(this.mapTarget.dataset.gmapUrl)
      .then(response => response.json())
      .then(data => this.add_markers(data, {cluster: options['cluster']}));
  }

}