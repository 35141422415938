import * as bootstrap from "bootstrap"

//---- Bootstrap initialization
window.addEventListener("turbo:load", function(event) {
  let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {  
    return new bootstrap.Popover(popoverTriggerEl)  
  })

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  var triggerTabList = [].slice.call(document.querySelectorAll('.nav-tabs button'))
  triggerTabList.forEach(function (triggerEl) {
    var tabTrigger = new bootstrap.Tab(triggerEl)

    triggerEl.addEventListener('click', function (event) {
      event.preventDefault()
      tabTrigger.show()
    })
  })

});

window.addEventListener("turbo:frame-load", function(event) {
  const modalContainerId= 'modal_container';
  if (event.target.id==modalContainerId) {
    // console.log("Loading modal window");
    const modalElm= event.target.firstChild;
    const modalOptions={};

    if (modalElm) {
      // console.log(`Activating modal window ${modalElm.id}`);
      let modal= new bootstrap.Modal(modalElm, modalOptions);
      modal.show();
    }
  }
});

